/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  Header: 'عطوفة مدير  عام دائرة ضريبة الدخل و المبيعات',
  FoterNickname: 'مدير اللجنة',
  EmployeeName: 'عزام معروف',
}
export const TaxSlice = createSlice({
  name: 'visibleTax',
  initialState: { visibleTax: initialValue },
  reducers: {
    setTax: (state, action) => {
      state.visibleTax = action.payload
    },
  },
})
export const { setTax } = TaxSlice.actions
export default TaxSlice.reducer
