/* eslint-disable */
export const MAJORS_ENUM = {
  DONORS_TITLE: 1, //لقب المتبرع
  DONOR_DESCRIPTION: 2, //وصف المتبرع
  DONOR_TYPE: 3, //نوع المتبرع
  DONOR_SOURCE: 4, //مصدر المتبرع
  DONOR_AREA: 5, //منطقة المتبرع
  COUNTRIES: 6, // الدول
  CITIES: 7, //المدن
  MEMBERSHIP_STATUS: 8, //صفة العضوية
  BANKS: 9, //البنوك
  BANKS_BRANSHES: 10, //فروع البنوك
  TYPES_OF_CENTER_ACCOUNTS: 11, //انواع حسابات المراكز
  CURRENCIES_USED: 12, //العملات المستخدمة
  RELATIONSHIP_OF_MALES_TO_THE_FATHER: 13, //صلة قرابة الذكور بالاب
  WARRANTY_STATUS: 14, //حالة الكفالة
  MARITAL_STATUS: 15, //الحالة الاجتماعية
  THE_NEED_FOR_THE_FAMILY: 16, //شدة احتياج الاسرة
  GENDER: 17, //الجنس
  NATIONALITY: 23, //الجنسية
  RELATIONSHIP_OF_FEMALES_TO_THE_DECEASED_FATHER: 18, //قرابة الإناث بالأب المتوفى
  ACADEMIC_LEVEL: 19, //المستوى العلمي
  QUALIFICATION: 20, //المؤهل العلمي
  JOBS: 22, //الوظائف
  GUARANTEE_PAYMENT_METHOD: 25, //طريقة دفع الكفالة
  GUARANTEE_PAYMENT_MECHANISM: 26, //آلية دفع الكفالة
  FEMALE_SOCIAL_STATUS: 28, //الحالة الاجتماعية للانثى
  THE_WIDOW_MARITAL_STATUS: 29, //الحالة الاجتماعية للارملة
  PAYMENT_METHOD: 31, //طريقة الدفع
  ACCOMMODATION_TYPE: 32, //نوع السكن
  EXCELLENT_TO_GOOD_SCALE: 33, // مقياس ممتاز الى سيء
  CONSTRUCTION_TYPE: 34, //نوع البناء
  ORPHAN_ACTIVITY_SCALE: 37, //مقياس نشاط اليتيم
  SCHOOL_STATUS: 42, //الحالة الدراسية
  SCHOOL_TYPE: 43, //نوع المدرسة
  CLASSROOM: 44, //الصف الدراسي
  REASONS_FOR_ACADEMIC_FAILURE: 45, //اسباب التقصير الدراسي
  THE_AMOUNT_OF_MEMORIZING_THE_QURAN: 46, //مقدار حفظ القرآن
  REASONS_FOR_ORPHAN_FAILURE_TO_PARTICIPATE_IN_THE_CENTER_ACTIVITIES: 47, //اسباب فشل اليتيم في الاشتراك في انشطة المركز
  MORAL_OBLIGATION: 48, //الالتزام الاخلاقي
  ORPHAN_HEALTH: 49, //صحة اليتيم
  ORPHAN_NEEDS: 50, //احتياجات اليتيم
  STATUS_BOND: 56, //حالة السند
  FATHER_SOCIAL_STATUS: 63, //الحالة الاجتماعية للاب
  ASSOCIATIONS_AND_ZAKAT_COMMITTEES: 250, //الجمعيات ولجان الزكاة
  PALESTINE_BANKS: 251, //بنوك فلسطين
  PALESTINE_BANKS_BRANSHES: 252, // فروع بنوك فلسطين
  ACCOUNT_STATUS: 253, //حالة الحساب
  GOVERNORATES_OF_PALESTINE: 259, //محافظات فلسطين
  TYPES_OF_CHARITABLE_ORGANIZATIONS: 261, //انواع المؤسسات الخيرية
  BANKS_OF_COMMITTEES_AND_ASSOCIATIONS_IN_ENGLISH: 262, //بنوك اللجان والجمعيات بالانجليزي
  BRANCHES_OF_COMMITTEES_AND_ASSOCIATIONS_BANKS_IN_ENGLISH: 263, //فروع بنوك اللجان والجمعيات بالانجليزي
  USER_TYPE: 271, //نوع المستخدمين
  HOW_TO_GET_COMMITTEE: 256, //طريقة التعرف على اللجنة
  DOCUMENTS_OF_ASSOCIATIONS_AND_CHARITABLE_COMMITTEES: 265, //وثائق الجمعيات زاللجان الخيرية
  HOW_TO_SEND_MAIL: 267, //طريقة ارسال البريد
  FAMILY_CLASSIFICATION: 283, //تصنيف الاسرة
  FOLLOW_UP_NOTES: 284, //ملاحظات متابعة الكافلين
  FAMILY_TYPE: 285, //انواع الاسر
  COMMUNICATION_TYPE: 257, //طريقة التواصل
  CONTACT_SUBJECT: 255, //موضوع الاتصال
  SPONSORSHIP_NOTES: 254, //ملاحظات الكفالة
  COMMITEE_BRANCHES: 260, //فروع اللجنة
  ZAKA_COMMITTE_ACCOUNT: 264, //PSHFHJ HGG[KM]
  REASONS_FOR_STOPPING_WARRANTY: 268, //اسباب وقف الكفالة
  EXCHANGE_CONDITION: 24, //حالة الصرف
  SPONSORSHIP_PAYMENT_TYPE: 25, // طريقة الدفع للكفالة
  PAYMENT_TYPE_DESCRIPTION: 26, // وصف طريقة الدفع
  BALANCE_AMOUNT_OF_SPONSORSHIP: 282, // بترصيد مبلغ الكفالة
  REASONS_FOR_CONTINUING_WARRANTY: 277, //اسباب استمرار الكفالة
  FILTER_SPONSORSHIP_CONDITION: 70, //فلتر حالة الكفالة
  EXPENSES_CLASSIFICATION: 280, //تصنيف الصرفية
  FAMILY_STATE: 286, //حالة الاسرة
  TYPES_EXCHANGES: 279, // انواع الصرفيات
  FINANCIAL_BOND: 21, //لسندات المالية
  CHECK_STATUS: 184, // حالة الشيك
  BUDGET_ACCOUNT: 185, // حساب الميزانية
  CONTROL_ACCOUNT: 186, // حساب السيطرة
  STATUS_ACCOUNT: 187, //طبيعة الحساب
  GOVERNORATES_PALESTINE: 259, //محافظات فلسطين
  DOCUMENT_STATUS: 278, // حالة ورود الوثائق
  RECEIPT_TYPE: 281, //نوع توثيق الاستلام
}
export const SCREEN_ENUM = {
  POSTAL_CODES: 9027, //الرموز البريدية
  TEXT_BOOK_FOR_DONATORS: 9104, //كتب ضريبية للمتبرعين
  ORPHAN_SPONSORSHIPS_TO_STOP: 9116, //"طلبات توقيف كفالات الايتام"
  EMERGENCY_CASH_PAY: 9136, //"صرفيات المساعدات الطارئة"
  GENERAL_AIDS_CANDIDATES: 9135, //"ترشيح الاسر للمساعده"
  GENERAL_FAMILY: 9134, //"اسر فقيره عامه"
  XMLGENERATORFRM: 9133, //"انشاء ملفات XML"
  NEEDYFAMILYSPONSORSHIPSTOPPINGMNU: 9132, //"توقيف كفالات اسر فقيرة"
  ORPHANSPONSORSHIPSTOPPINGFRM: 9131, // "توقيف كفالات ايتام"
  PAYMENTSFRM: 9130, // "اعدادات ومراسلات الصرفيات"
  TRANSLATENAMESFRM: 9129, // "ترجمة الاسماء العربية"
  ARABICNAMESTRANSLATIONFRM: 9128, // "قاموس الاسماء العربية المترجمة"
  DONATORPWDREP_FRM: 9127, // "طباعة بطاقات المتبرعين"
  PAYMENTATTACHEDFILESREP_FRM: 9126, // "طباعة وثائق الصرفيات"
  PAYMENTDOCUMENTSFOLLOWUPFRM: 9125, //"متابعة ورود وثائق الايتام"
  OLDORPHANSPONSORSHIPFOLLOWUPFRM: 9124, //"متابعات الايتام كبار السن"
  TASKSCHADULESETTINGSFRM: 9123, //"اعدادات الجدولة الالية"
  UNIFYDONATORFRM: 9122, //"توحيد بطاقات المتبرعين المكررة"
  NEEDYFAMILYPAYMENTREPORTFRM: 9121, //"تقارير صرفيات الاسر الفقيرة"
  PAYMENTREPORTFRM: 9120, //"تقارير صرفيات الايتام"
  TRACKMESSAGEFRM: 9119, //"متابعة الرسائل النصية"
  MESSAGINGFRM: 9118, // "الرسائل النصية والرسائل الالكترونية"
  SPONSORSHIPREPLACEMENTPROCESSFRM: 9117, // "استبدال كفالات الايتام"
  TEMPFORMFRM: 9115, // "الكفالات العامة"
  CHECKORPHANFRM: 9114, // "تدقيق الايتام"
  CHECKORPHANFAMILYFRM: 9113, //  "تدقيق اسر الايتام"
  ORPHANTEMPFRM: 9112, //  "الايتام غير المكفولين - الجمعيات"
  ORPHANFAMILYTEMPFRM: 9111, //   "اسر الايتام - الجمعيات"
  PRINTREPORTFRM: 9110, // "طباعة تقارير الايتام"
  DONORINSTITUTIONSFRM: 9109, // "كشف حساب المؤسسات المانحة"
  GLBALANCEANDINCOMESHEETREPFRM: 9108, //"الميزانية وقائمة الدخل"
  GLTRIALBALANCEWITHLEVELREPFRM: 9107, //"ميزان مراجعة حسب المستوى"
  GLCOSTCENTERTRANSACTIONFRM: 9106, //"كشف حساب مركز كلفة تفصيلي / اجمالي"
  //رقم الشاشة مكرر
  GLPAYMENTGENERATORFRM: 9105, //"اصدار سندات الصرف"
  ORPHANSPONSORSREPORTFRM: 9105, //"ارسال تقارير الايتام للمتبرعين"
  ///////////////
  ORPHANCARDFRM: 9103, //"كشف بطاقة كافل يتيم"
  PAYMENTCONDITIONFRM: 9102, //"اعدادات الصرفيات"
  COMMITTEECASHPAYMENTFRM: 9101, //"صرفيات المشاريع الخيرية للجمعيات واللجان"
  SPONSORSHIPREPLACEMENTFRM: 9099, // "كفالات الايتام المستبدلة"
  ORPHANRESERVATIONFRM: 9098, // "حجز استمارات الايتام"
  TELSEARCHFRM: 9097, //"استعلام هواتف المتبر عين"
  INCOMERECORDPRINTFRM: 9096, //"سجل الواردات"
  ZAKATFRM: 9095, //"تقرير صندوق الزكاة"
  DONATORPAYMENTFOLLOWUPFRM: 9094, //"متابعة الكافلين"
  INCOMEVOUCHERSUMMATIONFRM: 9093, // "مجاميع سندات القبض"
  DONATIONDISTRIBUTIONFRM: 9092, // "توزيع كفالات سند القبض"
  GLTRIALBALANCEMASTERREP_FRM: 9091, // "ميزان مراجعة"
  GLJOURNALREP_FRM: 9090, // "دفتر اليومية"
  GLACCOUNTTRANSACTIONDETREP_FRM: 9089, //  "كشف حساب تفصيلي"
  GLACCOUNTTREEREP_FRM: 9088, // "كشف باسماء الحسابات"
  POSTGLVOUCHERSFRM: 9087, // "ترحيل القيود"
  GLCHEQUEINQUERYFRM: 9086, // "استعلام الشيكات"
  GLMANUALINSERTEDCHEQUEINQUERYFRM: 9085, //"استعلام الشيكات المدخلة يدويا"
  GLINSERTCHEQUEFRM: 9084, //"ادخال الشيكات"
  GLACCOUNTFRM: 9083, //"شجرة الحسابات"
  GLVOUCHERVIEWFRM: 9082, // "استعراض القيود"
  GLVOUCHERCHARITYPAYMENTFRM: 9081, // "الصرف الخيري"
  GLPAYMENTVOUCHERFRM: 9080, //  "الصرف العام"
  GLCHARITYVOUCHERFRM: 9079, //"القيد الخيري"
  GLVOUCHERFRM: 9078, //"القيد العام"
  NEEDYFAMILYREPORTFRM: 9077, //"تقارير الاسر الفقيرة"
  ORPHANREPORTFRM: 9076, //"تقارير الايتام"
  //رقم الشاشة مكرر مع شاشة متابعة الكافلين
  // DONATORPAYMENTFOLLOWUPFRM: 9075, //"الاتصالات مع الكافلين"
  MEDIATORFRM: 9074, //"بطاقة الوسيط"
  RECEIPTBOOKFRM: 9073, // "استلام سندات القبض من صندوق الزكاة"
  COUNTRYCODEFRM: 9072, // "رموز الاتصالات الدولية"
  CHARITYPROJECTFRM: 9071, //"المشاريع الخيرية"
  COMMITTEEFRM: 9070, // "الجمعيات الخيرية"
  USERTRANSACTIONFRM: 9069, //"متابعة حركات النظام"
  BOOKVOUCHERFRM: 9068, // "دفاتر السندات"
  PROJECTMONEYTRANSFERFRM: 9067, // "تحويل الأرصدة بين المشاريع"
  OTHERPROJECTSPACKAGEPAYMENTPERSONSFRM: 9066, // "سند صرف عيني - أفراد"
  OTHERPROJECTSPACKAGEPAYMENTFAMILIESFRM: 9065, //"سند صرف عيني - أسر"
  OTHERPROJECTSCASHPAYMENTPERSONSFRM: 9064, //"سند صرف نقدي - أفراد"
  OTHERPROJECTSCASHPAYMENTFAMILIESFRM: 9063, //"سند صرف نقدي - أسر"
  STUDENTPERIODICRESEARCHREP_FRM: 9062, // "التقرير الدوري لطالب العلم"
  ACTIVITYMEMBERREP_FRM: 9061, //"تقرير متابعة اعضاء النشاطات"
  PERIODICFAMILYPROJECTLOANRESEARCHFRM: 9060, // "تقرير متابعة مشاريع التاهيل الاسري"
  FAMILYPROJECTLOANCASHPAYMENTFRM: 9058, //"صرف مخصصات التأهيل الأسري"
  FAMILYPROJECTLOANCASHRECIEPTFRM: 9057, //"تسديدات قروض التأهيل الأسري"
  FAMILYPROJECTLOANFRM: 9056, // "مشاريع التأهيل الأسري - طلبات غير معتمدة"
  FAMILYPROJECTLOANFRM2: 9055, // "مشاريع التأهيل الأسري - طلبات معتمدة"
  FAMILYPROJECTCENTERCASHPAYMENTFRM: 9054, // "صرفية التأهيل الاسري للمراكز"
  CENTERCASHPAYMENTFRM: 9048, //  "سند صرف نقدي للمراكز"
  CENTERPACKAGEPAYMENTFRM: 9047, // "سند صرف عيني للمراكز"
  TEACHERFRM: 9043, // "المدرسون"
  DOCTORFRM: 9042, // "الاطبـاء"
  HEALTHPAYMENTFRM: 9041, // "الصرفية الصحية"
  ATTENDANCEFRM: 9040, //"الحضور والغياب للمنتسبين"
  ACTIVITYMEMBEREVALUATIONFRM: 9039, //"تقدير النتسبين"
  DONATORVISITFRM: 9038, //"بطاقة زيارة المتبرع"
  VISITCARDFRM: 9037, //"بطاقة زيارة صحية"
  ACTIVITYMEMBERFRM: 9036, //"المنتسبين للنشاط"
  ACTIVITYFRM: 9034, //"تعريف النشاطات"
  ORPHANPAYMENTREP_FRM: 9033, //"تقرير صرفية الايتام"
  STUDENTPAYMENTREP_FRM: 9032, //"تقرير صرفية طلاب العلم"
  SYSTEMCONFIGRATIONSFRM: 9031, // "الاعدادات الرئيسية"
  NEEDYFAMILYPAYMENTREP_FRM: 9030, // "تقرير صرفية الاسر الفقيرة"
  CHANGEPASSWORDFRM: 9029, //"تغيير كلمة السر"
  ORPHANPERIODICRESEARCHREP_FRM: 9028, //"تقرير بطاقة يتيم"
  SYSTEMMINORSFRM: 9026, //"الرموز الفرعية"
  CENTERFRM: 9025, //"شاشة المركز"
  DONATORVISTFRM: 9010, // "بطاقة زيارة متبرع"
  //لا يوجد رقم للشاشات التالية
  ORPHANDEBITCREDITREP_FRM: null, // "تقرير كشف حساب الايتام"
  NEEDYFAMILYDEBITCREDITREP_FRM: null, //"تقرير كشف حساب الاسر الفقيرة"
  STUDENTDEBITCREDITREP_FRM: null, //"تقرير كشف حساب طلاب العلم"
  NEEDYFAMILYCOUNTSTATISTICSREP_FRM: null, //"احصائيات الاسرالفقيرة"
  ORPHANCOUNTSTATISTICSREP_FRM: null, //"احصائيات الايتام"
  STUDENTCOUNTSTATISTICSREP_FRM: null, //"احصائيات طالب العلم"
  DEBITCREDITCOMPARISONREP_FRM: null, // "تقرير مقارنه الايرادات والمصرفات"
  DEBITCREDITREP_FRM: null, //"تقرير كشف حساب"
  COUNTOFSPONSOREDREP_FRM: null, // "تقرير عام بالكفالات"
  ORPHANREP_FRM: null, // "استمارات الايتام"
  NEEDYFAMILYREP_FRM: null, // "تقرير بطاقة الاسرة الفقيرة"
  ORPHANFAMILYREP_FRM: null, // "تقرير بطاقة اسرة اليتيم"
  STUDENTREP_FRM: null, //"تقرير بطاقة طالب العلم"
  STUDENTFAMILYREP_FRM: null, //"تقرير بطاقة اسرة طالب العلم"
  /////////////////////
  STUDENTPAYMENTFRM: 9009, // "تاكيد صرفية طالب العلم"
  NEEDYFAMILYPAYMENTFRM: 9008, //"تاكيد صرفية الاسر الفقيرة"
  ORPHANPAYMENTFRM: 9007, //"تاكيد صرفية الايتام"
  CANCELSTUDENTFAMILYAPPROVED: 9006, //"الغاء اعتماد اسرة طالب العلم"
  STUDENTFAMILYAPPROVED: 9005, //"اعتماد اسرة طالب العلم"
  CANCELNEEDYFAMILYAPPROVED: 9004, //"الغاء اعتماد الاسر الفقيرة"
  NEEDYFAMILYAPPROVED: 9003, //"اعتماد الاسر الفقيرة"
  CANCELORPHANFAMILYAPPROVED: 9002, //"إلغاء اعتماد أسر الايتام"
  ORPHANFAMILYAPPROVED: 9001, // "اعتماد أسرة الايتام"
  PERMISSIONFRM: 1008, // "الصلاحيات"
  DONATORNEEDYFAMILYDEDUCTIONFRM: 3006, // "استقطاعات المتبرعين - أسر فقيرة"
  DONATORORPHANDEDUCTIONFRM: 2007, //  "استقطاعات المتبرعين - أيتام"
  PERIODICORPHANRESEARCHFRM: 2008, // "الأبحاث الدورية للأيتام"
  PERIODICNEEDYFAMILYRESEARCHFRM: 3007, //"الأبحاث الدورية للأسر الفقيرة"
  PERIODICORPHANFAMILYRESEARCHFRM: 2009, //"الأبحاث الدورية لأسر الأيتام"
  NEEDYFAMILYSPONSORSHIPFRM: 3005, // "بيانات كفالة الاسر الفقيرة"
  STUDENTDETAILFRM: 4006, // "البيانات الدراسية"
  ORPHANSPONSORSHIPFRM: 2006, // "بيانات كفالة الايتام"
  STUDENTSPONSORSHIPFRM: 4006, // "بيانات كفالة طالب العلم"
  NEEDYFAMILYNOTAPPROVEDFRM: 3002, //"الأسر الفقيرة غير المعتمدة"
  NEEDYFAMILYFRM: 3003, //"الأسر الفقيرة المعتمدة"
  ORPHANFAMILYMEMEBERFRM: 2005, //"أفراد أسرة اليتيم"
  STUDENTFRM: 4001, //"الطلاب المكفولين"
  NEEDYFAMILYSPONSORSHIPPROCESSFRM: 3001, //"تكفيل الأسر الفقيرة"
  STUDENTSPONSORSHIPPROCESSFRM: 4002, //"تكفيل طالب العلم"
  STUDENTREMINDERSPONSORSHIPFRM: 7005, // "تذكير دفعات طلاب العلم"
  ORPHANREMINDERSPONSORSHIPFRM: 7004, // "تذكير دفعات الايتام"
  STUDENTREMINDERREPORTFRM: 7003, // "تذكير تقارير طلاب العلم"
  ORPHANREMINDERREPORTFRM: 7001, // "تذكير تقارير الايتام"
  ORPHANPAYMENTFRM: 5003, // "صرفيات الايتام"
  NEEDYFAMILYPAYMENTFRM: 5004, //"صرفيات الاسر الفقيرة"
  STUDENTFAMILYMEMEBERFRM: 4005, //"أفراد أسرة طالب العلم"
  ORPHANFAMILYNOTAPPROVEDFRM: 2003, //"أسر الايتام غير المعتمدة"
  ORPHANFAMILYFRM: 9002, //"أسر الايتام المعتمدة"
  SEARCHFRM: 6001, //"شاشة الاستعلام"
  NEEDYFAMILYREMINDERREPORTFRM: 7002, //"تذكير تقارير الأسر الفقيرة"
  NEEDYFAMILYREMINDERSPONSORSHIPFRM: 7005, //"تذكير دفعات الاسر الفقيرة"
  PACKAGERECEIPTFRM: 5002, // "سند القبض العيني"
  PERIODICSTUDENTRESEARCHFRM: 4009, //"الأبحاث الدورية لطالب العلم"
  ORPHANSPONSORSHIPPROCESSFRM: 2002, //"تكفيل الايتام"
  //الاسم مكرر
  ORPHANFRM: 2001, //"الايتام المكفولين"
  NONORPHANFRM: 9100, //"الايتام غير المكفولين"
  INCOMEVOUCHERFRM: 5001, //"سند قبض"
  STUDENTPAYMENTFRM: 5005, //"صرفيات طلاب العلم"
  PERIODICSTUDENTFAMILYRESEARCHFRM: 4008, //"الأبحاث الدورية لأسر طالب العلم"
  DONATORSTUDENTDEDUCTIONFRM: 4007, // "استقطاعات المتبرعين - طلاب علم"
  PHONELISTFRM: 1004, // "دليل الهاتف"
  NEEDYFAMILYMEMEBERFRM: 3004, // "أفراد الأسرة الفقيرة"
  EMPLOYEEFRM: 1007, // "الموظفين"
  DONATORFRM: 1006, // "المتبرعون"
  STUDENTFAMILYNOTAPPROVED: 4003, //"أسر الطلاب غير المعتمدة"
  STUDENTFAMILYFRM: 4004, //"أسر الطلاب المعتمدة"
}
export const TABLES_ENUM = {
  //الهيئات الادارية و حسابات البنوك
  ADVISORY_VOLUNTARY_ORGANIZATIONS: 1, //الهيئات الاستشارية و التطوعية
  BANK_ACCOUNTS: 2, //حسابات البنوك

  //المستندات و الكشوفات
  DOCUMENTS_AND_STATEMENTS: 3, //المستندات و الكشوفات

  //بحث اللجان و الجمعيات الخيرية
  COMMITTEES_AND_CHARITIES_SEARCH: 4,

  //الأيتام المكفولين
  ORPHAN_REPORTS_1: 5, //تقارير الايتام (الجدول الاول)
  ORPHAN_REPORTS_2: 6, //تقارير الايتام (الجدول الثاني)
  ORPHAN_PAYMENTS: 7, //دفعات اليتيم
  ORPHAN_ALLOWANCES: 8, //مخصصات اليتيم
  ORPHAN_EXPENSES: 9, //مصروفات اليتيم
  ORPHANS_FAMILIES_ACCOUNT_NUMBER: 10, //اسر الأيتام/ارقام الحسابات
  SPONSORSHIP_DATA: 12, //بيانات الكفالة
  SPONSOR_COMMUNICATION: 13, //تواصل الكافل
  SPONSORED_ACCOUNT_NUMBERS: 14, //ارقام الحسابات

  //الأيتام غير المكفولين
  ACOUNTS_NUMBER: 11, //ارقام الحسابات

  //طلبات توقيف كفالات الايتام
  REQUESTS_TO_STOP_ORPHANS_SPONSORSHIP: 15,

  //متابعة الايتام كبار السن
  FOLLOW_UPON_OLDERLY_ORPHANS: 16,

  //استبدال كفالات الأيتام
  ORPHANS_INFO: 17,
  SPONSOR_INFO: 18,

  //كفالات الأيتام المستبدلة
  SPONSORING_ORPHANS_REPLACED: 19,

  //الأبحاث الدورية للأيتام
  FAMILY_ORPHAN: 20,

  //متابعة الكافلين
  FOLLOW_THE_SPONSOR: 21,

  //ترجمة الأسماء العربية
  ARABIC_NAMES_TRANSLATION: 22,

  //الأسر الفقيرة المكفولة
  POOR_FAMILIES_ACCOUNT_NUMBERS: 23, //أرقام الحسابات
  FAMILY_NOTES: 24, //ملاحظات الاسرة
  FAMILY_GUARANTEES: 25, //كفالات الاسرة
  FAMILY_PAYMENTS: 26, //دفعات الاسرة
  FAMILY_ALLOWANCES: 27, //مخصصات الاسرة
  SPONSOR_COMMUNICATION: 28, //تواصل الكافل

  //الأسر الفقيرة غير المكفولة
  UNSUPPORTED_FAMILIES_ACCOUNT_NUMBERS: 29, //ارقام الحسابات
  UNSUPPORTED_FAMILY_NOTES: 30, //ملاحظات الاسرة
  FAMILY_MEMBERS: 31, //افراد الأسرة

  //أفراد الاسر الفقيرة
  POOR_FAMILY_MEMBERS: 32,
  //استمارات  الأيتام
  ORPHANS_FORMS: 50,

  //حجز استمارات الأيتام
  ORPHANS_BOOKING_FORMS: 51,

  //ترشيحات الأسر المحتاجة للمساعدة
  NOMINATION_OF_FAMILIES_IN_NEED_ASSISTANCE: 52,
  //توقيف كفالات الأسر الفقيرة
  ENDING_POOR_FAMILIES_SPONSORSHIP: 33,

  //التقرير الدوري لليتيم
  ORPHANS_PERIODIC_REPORT: 53,

  // الكفالات
  ORPHAN_SPONSOR_SHIPS: 70, //الايتام المكفولين
  //بطاقة المتبرع
  DONATOR_NOTE: 71, //ملاحظات المتبرع
  //تكفيل الايتام
  ADD_ORPHAN_SPONSOR_SHIPS: 72,
  //تقرير صندوق الزكاة
  ZAKAT_FUND_REPORT: 73,
  EDIT_AMOUNT_ORPHAN: 74,
  EDIT_AMOUNT_ORPHAN_DISTRIBUTION: 75,
  EDIT_AMOUNT_ORPHAN_Projec: 76,
  EDIT_AMOUNT_ORPHAN_MONTH: 77,
  CHECK_QUERY: 78, //استعلام الشيكات
  CHARITYTABLE1: 79, //صرف خيري
  CHARITYTABLE2: 80, //صرف خيري
}
export const ACTION_ENUM = {
  ADD: 1,
  UPDATE: 2,
  PREVIEW: 3,
}

export const STEP_MOVEMENT = {
  NEXT_STEP: 1,
  PRE_STEP: 2,
  NEXT_MANY_STEP: 3,
  PRE_MANY_STEP: 4,
}
